<script setup>
import { computed, ref } from 'vue'
import { downloadFile } from '@modules/useHttp'
import LoadingPlaceholder from '@shared/loading/LoadingPlaceholder.vue'
import PrimaryButton from '@forms/PrimaryButton.vue'
import SelectMenu from '@forms/SelectMenu.vue'
import Icon from '@shared/icons/Icon.vue'
import { logImpression } from '@modules/useImpressions'
import { route } from '@/config'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  item: {
    type: Number,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  thumbnail: {
    type: String,
    required: true,
  },
  thumbnailMobile: {
    type: Object,
    default: () => ({ width: '96px', height: '96px' }),
    required: true,
  },
  thumbnailDesktop: {
    type: Object,
    default: () => ({ width: '120px', height: '120px' }),
    required: true,
  },
  downloadFileName: {
    type: String,
    required: true,
  },
  downloadFileUrl: {
    type: String,
    required: true,
  },
  impressionCategory: {
    type: String,
    required: true,
  },
  impressionType: {
    type: String,
    required: true,
  },
  impressionId: {
    type: Number,
    required: true,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
})

const thumbnailSizes = computed(() => {
  return `tw-w-[${props.thumbnailMobile.width}] md:tw-w-[${props.thumbnailDesktop.width}]`
})

const selectItem = ref(props.items.find(item => item.value === props.item))

const buildDownloadUrl = () => `${route.media.download}?url=${encodeURIComponent(props.downloadFileUrl)}&name=${encodeURIComponent(props.downloadFileName)}`

const handleDownload = () => {
  downloadFile(buildDownloadUrl(), props.downloadFileName)
  logImpression(props.impressionCategory, props.impressionType, props.impressionId)
}
</script>

<template>
  <div class="tw-flex tw-justify-center">
    <div class="tw-grid tw-grid-cols-3 tw-grid-rows-2 md:tw-grid-cols-1 md:tw-grid-rows-6">
      <div class="tw-row-span-2 tw-flex tw-items-center md:tw-row-span-3 md:tw-items-start md:tw-justify-center">
        <div :class="thumbnailSizes" class="tw-relative">
          <Icon v-if="isNew" icon="NewBadgeIcon" class="tw-absolute tw--right-2 tw--top-3 tw-h-5 md:tw--right-4" />

          <LoadingPlaceholder :width="thumbnailMobile.width" :height="thumbnailMobile.height" loader-width="65px" class="tw-block md:tw-hidden">
            <img v-if="thumbnail" :src="thumbnail" class="tw-shadow-md" alt="Thumbnail">
          </LoadingPlaceholder>

          <LoadingPlaceholder :width="thumbnailDesktop.width" :height="thumbnailDesktop.height" loader-width="65px" class="tw-hidden md:tw-block">
            <img v-if="thumbnail" :src="thumbnail" class="tw-shadow-md" alt="Thumbnail">
          </LoadingPlaceholder>
        </div>
      </div>

      <div class="tw-col-span-2 tw-ml-6 tw-w-40 md:tw-col-span-1 md:tw-row-span-2 md:tw-row-start-1 md:tw-ml-0 md:tw-w-auto md:tw-text-center lg:tw-w-40">
        <h4 class="tw-font-sans tw-text-xs tw-font-bold tw-uppercase tw-text-black" v-text="title" />
        <SelectMenu v-model="selectItem" :items="items" />
      </div>

      <div class="tw-col-span-2 tw-ml-6 tw-mt-1 tw-w-full tw-place-self-end tw-justify-self-start md:tw-col-span-1 md:tw-ml-0 md:tw-flex md:tw-justify-center">
        <PrimaryButton
          size="sm"
          icon="DownloadIcon"
          icon-class="tw-h-5"
          class="tw-hidden md:tw-inline-flex"
          :disabled="!downloadFileUrl"
          @click="handleDownload()"
        >
          Download
        </PrimaryButton>

        <div class="tw-inline-flex tw-flex-col tw-items-center tw-gap-y-1 md:tw-hidden">
          <PrimaryButton
            icon="DownloadIcon"
            icon-class="tw-h-5"
            type="circle"
            :disabled="!downloadFileUrl"
            @click="handleDownload()"
          />
          <div class="tw-text-2xs/none tw-font-semibold tw-text-primary-200">
            Download
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
